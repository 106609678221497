import React, { type Dispatch, type ReactElement, type SetStateAction, useCallback } from 'react';

import Checkbox from '@atlaskit/checkbox';
import { Box, Text, xcss } from '@atlaskit/primitives';

import { sendUIEvent } from '../../../../../common/utils/analytics-client';
import { PreferenceCategory } from '../../../../../types';
import type { ConsentPreference, InitializedPrefsData } from '../../../../../types';

export interface CookieCategorySectionProps {
	isChecked: boolean;
	category: PreferenceCategory;
	titleElement: ReactElement;
	titleString: string;
	descriptionElement: ReactElement;
	setPreferencesData: Dispatch<SetStateAction<InitializedPrefsData>>;
}

const tmpTrelloClassNameOverride = 'atl-bsc-preference-modal-checkbox';

// Styles
const categoryBoxStyles = xcss({
	paddingBlockStart: 'space.200',
});

const categoryDescriptionStyles = xcss({
	paddingInlineStart: 'space.300',
	marginInlineStart: 'space.050',
});

// We don't bother tracking unknown checkbox toggles
const categoryToEventSubjectMap = {
	[PreferenceCategory.Analytics]: 'performanceConsent',
	[PreferenceCategory.Functional]: 'functionalConsent',
	[PreferenceCategory.Marketing]: 'targetingConsent',
	[PreferenceCategory.Unknown]: null,
};

export const CookieCategorySection = ({
	category,
	titleElement,
	titleString,
	descriptionElement,
	isChecked,
	setPreferencesData,
}: CookieCategorySectionProps) => {
	const onCheckboxChange = useCallback(() => {
		// Don't alter cookies that are strictly necessary
		if (category === PreferenceCategory.StrictlyNecessary) {
			return;
		}

		setPreferencesData((prevPrefsData) => {
			const isSelected = prevPrefsData.prefs && !prevPrefsData.prefs[category];

			const actionSubjectId = categoryToEventSubjectMap[category];
			if (actionSubjectId) {
				sendUIEvent({
					actionSubjectId,
					action: 'clicked',
					actionSubject: 'checkbox',
					source: 'cookieConsentModal',
					attributes: { isSelected },
				});
			}

			return {
				...prevPrefsData,
				prefs: {
					...prevPrefsData.prefs,
					[category]: isSelected,
				} as ConsentPreference,
			};
		});
	}, [category, setPreferencesData]);

	return (
		<Box xcss={categoryBoxStyles}>
			<Checkbox
				// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-classname-prop
				className={`${tmpTrelloClassNameOverride}`}
				isChecked={isChecked}
				value={isChecked.toString()}
				onChange={onCheckboxChange}
				label={titleElement}
				name={`${titleString}`}
			/>
			<Box xcss={categoryDescriptionStyles}>
				<Text size="UNSAFE_small" color="color.text.subtle">
					{descriptionElement}
				</Text>
			</Box>
		</Box>
	);
};

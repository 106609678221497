import React from 'react';

import { injectIntl } from 'react-intl-next';

import { type CreateFlagArgs } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import SuccessIcon from '@atlaskit/icon/core/migration/success--check-circle';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { fg } from '@atlaskit/platform-feature-flags';
import { G300, P300, R300, Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import messages from './messages';

const ErrorWithIntl = injectIntl(({ intl }) => (
	<ErrorIcon
		color={token('color.icon.danger', R300)}
		LEGACY_size="medium"
		label={intl.formatMessage(messages.error)}
		spacing={fg('eoc-visual-refresh-phase-two') ? 'spacious' : 'none'}
	/>
));

const WarningWithIntl = injectIntl(({ intl }) => (
	<WarningIcon
		color={token('color.icon.warning', Y300)}
		LEGACY_size="medium"
		label={intl.formatMessage(messages.warning)}
		spacing={fg('eoc-visual-refresh-phase-two') ? 'spacious' : 'none'}
	/>
));

const InfoWithIntl = injectIntl(({ intl }) => (
	<InfoIcon
		color={token('color.icon.discovery', P300)}
		LEGACY_size="medium"
		label={intl.formatMessage(messages.info)}
		spacing={fg('eoc-visual-refresh-phase-two') ? 'spacious' : 'none'}
	/>
));

const SuccessWithIntl = injectIntl(({ intl }) => (
	<SuccessIcon
		color={token('color.icon.success', G300)}
		LEGACY_size="medium"
		label={intl.formatMessage(messages.success)}
		spacing={fg('eoc-visual-refresh-phase-two') ? 'spacious' : 'none'}
	/>
));

export const errorToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <ErrorWithIntl />,
	appearance: 'normal' as const,
});

export const warningToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <WarningWithIntl />,
	appearance: 'normal' as const,
});

export const infoToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <InfoWithIntl />,
	appearance: 'normal' as const,
});

export const successToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <SuccessWithIntl />,
	appearance: 'normal' as const,
	isAutoDismiss: true,
});

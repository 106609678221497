import React, { useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { FlagsProvider } from '@atlaskit/flag';
import { fg } from '@atlaskit/platform-feature-flags';

import { Logger } from '../../common/utils/logger';

import { ConsentBannerImpl } from './consent-banner-impl';
import type { ConsentBannerProps } from './types';

export const ConsentBanner = (props: ConsentBannerProps) => {
	if (
		fg('platform_moonjelly_bsc_master_killswitch') ||
		fg('platform_moonjelly_bsc_ui_killswitch')
	) {
		const { onConsentBannerBailOut } = props;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			onConsentBannerBailOut?.();
		}, [onConsentBannerBailOut]);
		// Don't render the consent banner if the killswitch is enabled
		return null;
	}

	return (
		<ErrorBoundary
			FallbackComponent={() => <div data-testid="consent-banner-render-error"></div>}
			onError={(e: any) => {
				Logger.errorWithOperationalEvent({
					action: 'renderConsentBannerError',
					message: e.message || '',
				});
			}}
		>
			<FlagsProvider>
				<ConsentBannerImpl {...props} />
			</FlagsProvider>
		</ErrorBoundary>
	);
};

/* eslint-disable @atlassian/tangerine/import/no-parent-imports */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { FlagsProvider, useFlags } from '@atlaskit/flag';
import { errorToastProps, successToastProps } from '@atlassian/eoc-common';

import {
	initializeGlobalState,
	sendPackageOperationalEvent,
	sendTrackEvent,
	sendUIEvent,
} from '../../../common/utils';
import { BSCIntlProvider } from '../../../common/utils/locale';
import { Logger } from '../../../common/utils/logger';
import {
	ACCEPT_ALL_PREFERENCES,
	ATLASSIAN_BANNER_LAST_DISMISSED_TIME_COOKIE_KEY,
	ONLY_NECESSARY_PREFERENCES,
} from '../../../constants';
import { deleteCookie, getCookie } from '../../../controllers/cookie-controls';
import { updatePreferences } from '../../../controllers/preferences-controls/update-preferences';
import type { ConsentDisplayedTextMap } from '../../../services/consent-hub-service/types';
import { i18n } from '../messages';
import { PreferenceModal } from '../preference-modal';
import usePreferences from '../preference-modal/use-preferences';
import { PresentationalConsentBanner } from '../presentational-consent-banner';
import type { ConsentBannerProps } from '../types';
import useBannerVisibility from '../use-banner-visibility';
import usePrevious from '../use-previous';

export const ConsentBannerImpl = ({
	onShow,
	onHide,
	initialPreferences,
	minWidth = 400,
	product,
	onConsentBannerBailOut,
	analyticsEnabled = true,
	localConsentMode = false,
}: ConsentBannerProps) => {
	const firstRender = useRef(true);
	useEffect(() => {
		if (!firstRender.current) {
			return;
		}

		// Send an operational event if the banner is shown after previously having
		// been dismissed. This will help prevent issues with the banner
		// inappropriately reappearing.
		const lastBannerDismissal = getCookie(ATLASSIAN_BANNER_LAST_DISMISSED_TIME_COOKIE_KEY);
		if (lastBannerDismissal) {
			const timeSinceDismissal =
				Date.now() - new Date(Number.parseInt(lastBannerDismissal)).getTime();

			const lessThanOneHour = timeSinceDismissal < 1000 * 60 * 60;
			const lessThanOneDay = timeSinceDismissal < 1000 * 60 * 60 * 24;
			const lessThanOneWeek = timeSinceDismissal < 1000 * 60 * 60 * 24 * 7;

			sendPackageOperationalEvent({
				action: 'bannerAppearanceAfterDismissal',
				attributes: { timeSinceDismissal, lessThanOneHour, lessThanOneDay, lessThanOneWeek },
			});

			deleteCookie(ATLASSIAN_BANNER_LAST_DISMISSED_TIME_COOKIE_KEY);
		}

		firstRender.current = false;
	}, []);

	useEffect(() => {
		initializeGlobalState({ analyticsEnabled, product, localConsentMode });
	}, [analyticsEnabled, product, localConsentMode]);

	const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);

	const intl = useIntl();

	const { showFlag } = useFlags();

	const { preferences, isPrefetchedPreferencesState, isInitialRender } = usePreferences({
		initialPreferences,
	});

	const { showBanner, setIsDismissed } = useBannerVisibility({
		preferences,
		isPrefetchedPreferencesState,
		initialPreferences,
		isInitialRender,
	});

	const closePreferenceModal = useCallback(() => {
		setIsPreferencesModalOpen(false);
	}, [setIsPreferencesModalOpen]);

	const onPreferenceModalSubmit = useCallback(() => {
		closePreferenceModal();
		setIsDismissed(true);
	}, [closePreferenceModal, setIsDismissed]);

	const onClickPreferences = useCallback(() => {
		setIsPreferencesModalOpen(true);

		sendUIEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'preferences',
			source: 'cookieConsentBanner',
		});
	}, []);

	const showSuccessFlag = useCallback(() => {
		if (localConsentMode) {
			showFlag({
				...successToastProps(),
				id: 'cookieConsentBanner',
				description: intl.formatMessage(i18n.successFlagDescriptionLocalOnly),
				title: intl.formatMessage(i18n.successFlagTitle),
			});
		} else {
			showFlag({
				...successToastProps(),
				id: 'cookieConsentBanner',
				description: intl.formatMessage(i18n.successFlagDescription),
				title: intl.formatMessage(i18n.successFlagTitle),
			});
		}
	}, [intl, localConsentMode, showFlag]);

	const showErrorFlag = useCallback(() => {
		showFlag({
			...errorToastProps(),
			id: 'cookieConsentBanner',
			description: intl.formatMessage(i18n.errorFlagDescription),
			title: intl.formatMessage(i18n.errorFlagTitle),
		});
	}, [intl, showFlag]);

	// Sets preferences to allow only Strictly Necessary cookies
	const onClickOnlyNecessary = useCallback(
		async (displayedTextMap: ConsentDisplayedTextMap) => {
			try {
				sendUIEvent({
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'onlyNecessary',
					source: 'cookieConsentBanner',
				});

				await updatePreferences(ONLY_NECESSARY_PREFERENCES, displayedTextMap);

				sendTrackEvent({
					source: 'cookieConsentBanner',
					action: 'submitted',
					actionSubject: 'cookieConsentPreferences',
				});

				showSuccessFlag();
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'updatePreferencesError',
					attributes: {
						preferences: ONLY_NECESSARY_PREFERENCES,
						product,
					},
					message: `Failed to update preferences from banner. ${e.message || ''}`,
				});

				showErrorFlag();
			} finally {
				setIsDismissed(true);
			}
		},
		[product, showErrorFlag, showSuccessFlag, setIsDismissed],
	);

	// Sets preferences to allow all categories of cookies
	const onClickAcceptAll = useCallback(
		async (displayedTextMap: ConsentDisplayedTextMap) => {
			try {
				sendUIEvent({
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'acceptAll',
					source: 'cookieConsentBanner',
				});

				await updatePreferences(ACCEPT_ALL_PREFERENCES, displayedTextMap);

				sendTrackEvent({
					source: 'cookieConsentBanner',
					action: 'submitted',
					actionSubject: 'cookieConsentPreferences',
				});

				showSuccessFlag();
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'updatePreferencesError',
					attributes: {
						preferences: ACCEPT_ALL_PREFERENCES,
						product,
					},
					message: `Failed to update preferences from banner. ${e.message || ''}`,
				});

				showErrorFlag();
			} finally {
				setIsDismissed(true);
			}
		},
		[product, showErrorFlag, showSuccessFlag, setIsDismissed],
	);

	const onClickTrackingNoticeLink = useCallback(() => {
		sendUIEvent({
			action: 'clicked',
			actionSubject: 'link',
			actionSubjectId: 'cookiesTrackingNotice',
			source: 'cookieConsentBanner',
		});
	}, []);

	// Call onShow/onHide as a side effect of showBanner changing, rather than
	// possibly having to call them in multiple places.
	const prevShowBanner = usePrevious(showBanner);
	useEffect(() => {
		if (showBanner && !prevShowBanner) {
			onShow?.();
		} else if (!showBanner && prevShowBanner) {
			onHide?.();
		}
	}, [showBanner, prevShowBanner, onShow, onHide]);

	useEffect(() => {
		// when banner is not visible and isPrefetchedPreferencesState is in "resolved" state
		if (!showBanner && isPrefetchedPreferencesState !== undefined) {
			// report "banner decided not to show" to sync decision with other components
			onConsentBannerBailOut?.();
		}
	}, [showBanner, isPrefetchedPreferencesState, onConsentBannerBailOut]);

	if (!showBanner) {
		return null;
	}

	return (
		<FlagsProvider>
			<BSCIntlProvider>
				<PresentationalConsentBanner
					onClickOnlyNecessary={onClickOnlyNecessary}
					onClickAcceptAll={onClickAcceptAll}
					onClickPreferences={onClickPreferences}
					onClickTrackingNoticeLink={onClickTrackingNoticeLink}
					minWidth={minWidth}
				/>
				{isPreferencesModalOpen && (
					<PreferenceModal
						onClose={closePreferenceModal}
						onSubmit={onPreferenceModalSubmit}
						showSuccessFlag={showSuccessFlag}
						showErrorFlag={showErrorFlag}
					/>
				)}
			</BSCIntlProvider>
		</FlagsProvider>
	);
};
